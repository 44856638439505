import React, { useState } from "react";
import { ListPageShell } from "../../components/ListPageShell";
import {
  useGetApiUserGetUsersQuery,
  usePostApiUserUploadUserForUpdateMutation,
  usePostApiUserDownloadUsersMutation,
  usePostApiUserUploadUserMutation
} from "../../services/appcenterApi";
import { UsersDataTable } from "../../features/users/UsersDataTable";
import { ActionIcon, Anchor, Button, FileInput, Group, Stack, Divider } from "@mantine/core";
import { IconWithTooltip } from "@ascentis/cx-reactjs-ui";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { selectAccessToken, selectOrganizationId } from "../../features/user/userSlice";
import { useAppSelector } from "../../hooks/reduxHelper";
import { showNotification } from "@mantine/notifications";
import { openModal, closeModal } from "@mantine/modals";
import { Text } from "@mantine/core";

function UploadUserModalContent() {
  const { t } = useTranslation();

  const organizationId: string = selectOrganizationId(useAppSelector(state => state));
  const [value, setValue] = useState<File | null>(null);

  const [uploadFile, { isLoading, isSuccess, isError, error }] = usePostApiUserUploadUserMutation();

  const handleUpload = () => {
    if (value !== null) {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        // reader.result contains the contents of blob as a typed array
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');

        uploadFile({ "uploadUserCommand": { organizationId, file: { name: value.name, data: base64String, type: 'text/csv' } } })
          .then(res => {
            if (res.error != null) {
              throw new Error(res.error.data);
            }
            //trigger data url
            showNotification({
              title: t('users.list.upload_success_title'),
              message: t('users.list.upload_success_message'),
              color: 'green',
              icon: <FontAwesomeIcon icon={faDownload} />,
              autoFocus: true,
            });
            window.location.href = `data:text/csv;base64,${res.data.data}`

          }).catch(err => {
            console.log(err);
            showNotification({
              title: t('users.list.upload_error_title', { context: '403' }),
              message: t('users.list.upload_error_message', { context: '403' }),
              color: 'red',
              icon: <FontAwesomeIcon icon={faXmark} />,
              autoFocus: true,
            })
          }).finally(() => { closeModal('upload-user-modal') });
      });
      reader.readAsDataURL(value);
    }
  };

  return (
    <>
      <Stack>
        <Text>{t('users.list.upload_description')}</Text>        
        <Anchor href="/User_Upload_Template.csv" target="_blank">{t('users.list.upload_template')}</Anchor>
        <Divider/>
        <FileInput placeholder={t("users.list.upload_file_input_placeholder")} value={value} onChange={setValue} accept="text/csv" label={t("users.list.upload_file")} />
        <Group position="right">
          <Button disabled={ value==null} onClick={() => handleUpload()}>{t('users.list.upload_submit')}</Button>
        </Group>
      </Stack>
    </>
  );
}

function UploadUpdateUserModalContent() {
  const { t } = useTranslation();

  const organizationId: string = selectOrganizationId(useAppSelector(state => state));
  const [value, setValue] = useState<File | null>(null);

  const [uploadFile, { isLoading, isSuccess, isError, error }] = usePostApiUserUploadUserForUpdateMutation();

  const handleUpload = () => {

    console.log("file", value);

    if (value !== null) {

      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        // reader.result contains the contents of blob as a typed array
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');

        uploadFile({ "uploadUserForUpdateCommand": { organizationId, file: { name: value.name, data: base64String, type: 'text/csv' } } })
          .then(res => {
            if (res.error != null) {
              throw new Error(res.error.data);
            }
            //trigger data url
            console.log(res)
            //window.URL.createObjectURL(res.data.data);
            showNotification({
              title: t('users.list.upload_success_title'),
              message: t('users.list.updateUpload_success_message'),
              color: 'green',
              icon: <FontAwesomeIcon icon={faDownload} />,
              autoFocus: true,
            });
            window.location.href = `data:text/csv;base64,${res.data.data}`

          }).catch(err => {
            console.log(err);
            showNotification({
              title: t('users.list.upload_error_title', { context: '403' }),
              message: t('users.list.updateUpload_error_message', { context: '403' }),
              color: 'red',
              icon: <FontAwesomeIcon icon={faXmark} />,
              autoFocus: true,
            })
          }).finally(() => { closeModal('upload-user-modal') });
      });
      reader.readAsDataURL(value);
    }


  };

  return (
    <>
      <Stack>
        <Text>{t('users.list.updateUpload_description')}</Text>
        <Anchor href="/User_Update_Upload_Template.csv" target="_blank">{t('users.list.upload_template')}</Anchor>
        <Divider/>
        <FileInput placeholder={t('users.list.upload_file_input_placeholder')} value={value} onChange={setValue} accept="text/csv" label={t("users.list.upload_file")} />
        <Group position="right">
          <Button disabled={value==null} onClick={() => handleUpload()}>{t('users.list.upload_submit')}</Button>
        </Group>
      </Stack>
    </>
  );
}

export function ListUsers() {


  const { t } = useTranslation()


  const organizationId = selectOrganizationId(useAppSelector(state => state));
  const access_token = selectAccessToken(useAppSelector(state => state));
  const webserviceUrl = () => window.env.appCenterApiUrl

  const onUploadClick = () => {
    //Open Upload windows
    openModal({
      title: t('users.list.upload'),
      children: <UploadUserModalContent />,
      size: 'md',
      modalId: 'upload-user-modal'
    })
  }

  const onUpdateUploadClick = () => {
    openModal({
      title: t('users.list.updateUpload'),
      children: <UploadUpdateUserModalContent />,
      size: 'md',
      modalId: 'upload-user-modal'
    })
  }

  const onExportClick = () => {

    //https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
    //https://stackoverflow.com/questions/4545311/download-a-file-by-jquery-ajax/29939024#29939024
    //need to use fetch
    //download(organizationId)

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }

    fetch(webserviceUrl() + "/api/User/DownloadUsers?organizationId=" + organizationId, options)
      .then(res => {
        if (res.ok) {
          return res.blob();
        } else {
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .then(blob => {

        let file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      })
      .catch(err => {
        showNotification({
          title: t('error.title', { context: '403' }),
          message: t('error.message', { context: '403' }),
          color: 'red',
          icon: <FontAwesomeIcon icon={faXmark} />,
          autoFocus: true,
        })
      })
  }



  return (
    <>
      <ListPageShell
        i18Prefix={"users"}
        initialSortKey={"userFullName"}
        useQuery={useGetApiUserGetUsersQuery} DataTable={UsersDataTable}
        topLeftTableActions={
          <>
            <ActionIcon onClick={onExportClick}>
              <IconWithTooltip text={t('users.list.export')} icon={faDownload} />
            </ActionIcon>
            <ActionIcon onClick={onUploadClick}>
              <IconWithTooltip text={t('users.list.upload')} icon={faUpload} />
            </ActionIcon>
            <ActionIcon onClick={onUpdateUploadClick}>
              <IconWithTooltip text={t('users.list.updateUpload')} icon={faUpload} />
            </ActionIcon>
          </>
        }
      />
    </>
  );
}