import { Anchor, Breadcrumbs, Code, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import * as React from "react";
import { Trans, Translation, useTranslation } from "react-i18next";

import { useAppSelector } from "shared-library/src/hooks/reduxHelper";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ContentShell } from "@ascentis/cx-reactjs-ui";
import { EntityUpdateHistory } from "shared-library/src/features/audit/EntityUpdateHistory";

export function ViewOrganizationAuditEvent() {
  const { t } = useTranslation();
  //const access_token = useAppSelector((state) => state.user?.access_token);
  //useGetUserInfoQuery(access_token !== "" ? {} : skipToken);
  //const params = useParams();
const organizationId = useAppSelector((state) => state.user.organization.id);
const i18prefix = 'organization'

  return (
    <>
      <ContentShell  bottomSection={<></>} topSection={<>
        <Breadcrumbs>
          <Anchor span={true} variant={"text"}>
            {t("list.title", { context: i18prefix, count: 1 })}
          </Anchor>
        </Breadcrumbs>
      </>}>
        <EntityUpdateHistory context={i18prefix} entityId={organizationId} />
      </ContentShell>
    </>
  );
}
