import { Anchor, Breadcrumbs } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/query";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { UpdateSecurityPolicyForm } from "../../components/UpdateSecurityPolicyForm";
import {
  SecurityPolicyFormProvider,
  SecurityPolicyResponseTransformation,
  useSecurityPolicyForm,
} from "../../features/organizations/SecurityPolicyFormContext";
import { SecurityPolicyInputs } from "../../features/organizations/SecurityPolicyInputs";
import { useGetOrganizationSecurityPolicyQuery } from "../../features/organizations/useGetOrganizationSecurityPolicyQuery";
import { selectOrganizationId } from "../../features/user/userSlice";
import { useAppSelector } from "../../hooks/reduxHelper";
import { useWithCurrentOrganization } from "../../hooks/useWithCurrentOrganization";
import {
  useGetApiOrganizationGetOrganizationQuery,
  usePostApiOrganizationUpdateOrganizationPolicyMutation,
} from "../../services/appcenterApi";

export function UpdateOrganizationSecurityPolicy() {
  const { t } = useTranslation();
  const i18Prefix = "organizationSecurityPolicy";

  const params = useParams();

  //this will be cached so its fine.
  const {
    data,
    error: queryError,
    isLoading: queryIsLoading,
    isSuccess: queryIsLoaded,
  } = useWithCurrentOrganization(useGetApiOrganizationGetOrganizationQuery)(
    params ?? skipToken
  );

  return (
    <>
      <UpdateSecurityPolicyForm
        topSection={
          <>
            <Breadcrumbs>
              <Anchor component={Link} to={".."}>
                {t("list.title", { context: "organization", count: 2 })}
              </Anchor>
              <Anchor component={Link} to={"../edit/" + params.organizationId}>
                {t("update.title", { context: "organization", data: data })}
              </Anchor>
              <Anchor span={true} variant={"text"}>
                {t("update.title", { context: i18Prefix, data: data })}
              </Anchor>
            </Breadcrumbs>
          </>
        }
        FormProvider={SecurityPolicyFormProvider}
        inputs={<SecurityPolicyInputs />}
        i18Prefix={"organizationSecurityPolicy"}
        useUpdateMutation={
          usePostApiOrganizationUpdateOrganizationPolicyMutation
        }
        useFormHook={useSecurityPolicyForm}
        commandKey={"updateOrganizationPolicyCommand"}
        transformResponse={SecurityPolicyResponseTransformation}
        useLoadQuery={useWithCurrentOrganization(
          useGetApiOrganizationGetOrganizationQuery
        )}
      />
    </>
  );
}
